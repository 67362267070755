@tailwind base;
@tailwind components;
@tailwind utilities;
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #351e5f; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #4e0097; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 0.1px solid #4e0097; /* creates padding around scroll thumb */
}
.react-pdf__Page__textContent  {
 background: transparent !important;
 display: none !important;
}